window.rwd || (window.rwd = {});

;(function($package, ScreenSize, UIComponent) {

    var TableUtils = {
        makeMultipleTableByRows: function(table, options) {
            options = options || {};
            // to jest używane tylko przec calc gotówkowy i metody są zafiksowane poniżej a nie przekazane przez argument
            options.rowPostprocessing = function($row) {
                $row.find(".title_row").remove();
                $row.find(".installment_value_row").prependTo($row);
                $row.find(".installment_value_row").after($row.find(".assurance_value_row"));
                $("<td>").addClass("c button_row").append($row.find(".rataOpisDodatkowy")).appendTo($row);
                return $row;
            }
            options.headerValueProvider = function($row) {
                return "<span class=\"percentage\">" + $row.find(".percentage").html() + "</span>" + $row.children().first().find(".title").html();
            }

            var defaults = {
                showClass: "visible-xs",
                hideClass: "hidden-xs",
                headerElementName: "h3",
                headerValueProvider: function($row) {
                    return $row.children().first().text();
                },
                cellHeaderAttributeName: "data-header",
                cellHeaderElementClassName: "cell-header",
                rowPostprocessing: function($row) {
                    return $row;
                }
            };
            options = $.extend({}, defaults, options);

            var $table = $(table);
            var $rows = $table.find("> tbody > tr");
            var $headItems = $table.find("> thead > tr").children();
            var count = $rows.length;
            var $c = $("<div>").addClass("table-clones " + options.showClass);
            $table.after($c);
            var $template = $table.clone().removeClass(options.hideClass); // na wypadek jakby było kolejne klonowanie po dodaniu tej klasy
            $template.find("> tbody").html("");
            for (var i=0; i < count; i++) {
                var $row = $($rows[i]).clone();
                $c.append($("<"+options.headerElementName+">").html(options.headerValueProvider($row)));
                $row.children().each(function(index) {
                    $(this)
                        .attr(options.cellHeaderAttributeName, $($headItems[index]).text())
                        .prepend($("<div>").addClass(options.cellHeaderElementClassName).html($($headItems[index]).html()));
                });
                var $clone = $template.clone();
                $clone.find("> tbody").append(options.rowPostprocessing($row));
                $c.append($clone);
            }
            $table.addClass(options.hideClass);
            return $c[0];
        }
    };

    $package.TableUtils = TableUtils;

    // ScrollableTable

    extendz(ScrollableTable, UIComponent);
    $package.ScrollableTable = ScrollableTable;
    function ScrollableTable(node, options) {
        UIComponent.call(this, node);
        this.options = $.extend({}, ScrollableTable.defaults, options);
        this.set = null;
        this.syncHeightTimeout = -2;
        this.init();
        if (this.options.onCondition.call(this)) {
            this.on();
        }
        ScrollableTable.all.push(this);
    }
    ScrollableTable.all = [];
    ScrollableTable.defaults = {
        onCondition: function() {
            var $node = $(this.node);
            if ($node.hasClass("table_type2_mode_xs")) {
                return ScreenSize.is(ScreenSize.SIZES.XSMALL)
            } else if ($node.hasClass("table_type2_mode_sm")) {
                return ScreenSize.is(ScreenSize.SIZES.XSMALL) || ScreenSize.is(ScreenSize.SIZES.SMALL);
            }
            return ScreenSize.is(ScreenSize.SIZES.XSMALL) || ScreenSize.is(ScreenSize.SIZES.SMALL) || ScreenSize.is(ScreenSize.SIZES.MEDIUM);
        }
    };
    ScrollableTable.makeScrollableTable = function(table) {
        var $headClone, $bodyClone;
        var $table = $(table);
        var $c = $("<div>").addClass("scrollable_table scrollable_table_off");
        $c.append($("<div>").addClass("head").append($("<div>").addClass("width_setter")));
        $c.append($("<div>").addClass("body").append($("<div>").addClass("width_setter")));
        $table.after($c);

        $headClone = $table.clone().removeClass(UIComponent.COMMON_CLASS_NAME);
        $c.find(".head > .width_setter").append($headClone);
        $bodyClone = $table.clone().removeClass(UIComponent.COMMON_CLASS_NAME);
        $c.find(".body > .width_setter").append($bodyClone)
            .width(ScrollableTable.getBodyPartWidth(table));

        return $c[0];
    }
    ScrollableTable.prototype.init = function() {
        this.set = ScrollableTable.makeScrollableTable(this.node);
    }
    ScrollableTable.prototype.isOn = function() {
        return $(this.set).hasClass("scrollable_table_on");
    }
    ScrollableTable.prototype.on = function() {
        var $node = $(this.node).hide();
        $(this.set).removeClass("scrollable_table_off").addClass("scrollable_table_on");
        this.update();
        this.onOn && this.onOn();
    }
    ScrollableTable.prototype.off = function() {
        $(this.node).show();
        $(this.set).removeClass("scrollable_table_on").addClass("scrollable_table_off");
        this.onOff && this.onOff();
    }
    ScrollableTable.prototype.toggle = function() {
        if (this.options.onCondition.call(this)) {
            this.on();
        } else {
            this.off();
        }
    }
    ScrollableTable.prototype.update = function() {
        if (!$(this.set).is(":visible")) {
            return;
        }
        this._syncHeight0("");
        this._syncHeight();
    }
    ScrollableTable.toggle = function() {
        $.each(this.all, function(index, item) { item.toggle(); });
    }
    ScrollableTable.getBodyPartWidth = function(table) {
        var $table = $(table);
        var width = $table.data("scrollable-width");
        if (width) {
            return width;
        }
        var cellWidth = $table.data("scrollable-cell-width") || 180; // to ta sama wartość co w css
        return ($table.find("> thead, > tbody").find("> tr").first().find("> td, > th").length-1) * cellWidth;
    }
    ScrollableTable.prototype._syncHeight = function() {
        var that = this;
        clearTimeout(this.syncHeightTimeout);
        this.syncHeightTimeout = -1;

        var loaded = true;
        if ($(this.set).find("> .head .width_setter > TABLE").find("th, td").height() == 0) {
            loaded = false;
        }
        if (loaded) {
            this.syncHeightTimeout = setTimeout(function() { that._syncHeight0(); }, 500);
        } else {
            this.syncHeightTimeout = setTimeout(function() { that._syncHeight.apply(that, arguments); }, 200);
        }
    }
    ScrollableTable.prototype._syncHeight0 = function(maxHeight) {
        var $headRows = $(this.set).find("> .head > .width_setter > TABLE").find("> thead, > tbody").find("> tr");
        var $bodyRows = $(this.set).find("> .body > .width_setter > TABLE").find("> thead, > tbody").find("> tr");
        $headRows.each(function(index) {
            var head = $(this).children();
            var body = $bodyRows.slice(index, index+1).children().slice(1);
            var max = typeof maxHeight != "undefined" ? maxHeight : Math.max(head.outerHeight(), body.outerHeight());
            head.first().outerHeight(max);
            body.first().outerHeight(max);
        });
    }

    $(document).onPage(ScreenSize.SIZE_CHANGE_EVENT_NAME, function(e, data) {
        ScrollableTable.toggle();
    });

    // MultipleTable

    $package.MultipleTable = MultipleTable;
    extendz(MultipleTable, UIComponent);
    function MultipleTable(table, options) {
        UIComponent.call(this, table);
        var defaults = {
            showClass: "visible-xs",
            hideClass: "hidden-xs",
            onCondition: function() {
                return ScreenSize.is(ScreenSize.SIZES.XSMALL);
            }
        };
        var options = this.options = $.extend({}, defaults, options);

        var $table = $(table);
        var $th = $table.find("> thead > tr").find("> th, > td");
        var count = $th.length-1;
        var $c = $("<div>").addClass("table-clones " + options.showClass);
        $table.after($c);
        for (var i=0; i < count; i++) {
            var $clone = $table.clone().removeClass(options.hideClass);
            hideTableColumnExcept($clone, i+1);
            $c.append($("<h3>").html($th.slice(i+1, i+2).html()));
            $c.append($clone);
        }
        $table.addClass(options.hideClass);
        MultipleTable.all.push(this);
    }
    MultipleTable.all = [];
    MultipleTable.toggle = function() {
        $.each(this.all, function(index, item) { item.toggle(); });
    }
    MultipleTable.prototype.isOn = function() {
        return this.options.onCondition();
    }
    MultipleTable.prototype.toggle = function() {
        if (this.options.onCondition()) {
            this.on();
        } else {
            this.off();
        }
    }
    MultipleTable.prototype.on = function() {
        this.onOn && this.onOn();
    }
    MultipleTable.prototype.off = function() {
        this.onOff && this.onOff();
    }
    $(document).on(ScreenSize.SIZE_CHANGE_EVENT_NAME, function(e, data) {
        MultipleTable.toggle();
    });

    function hideTableColumnExcept(table, index) {
        $(table).find("> THEAD > TR, > TBODY > TR").each(function() {
            for (var i=0; i < this.cells.length; i++) {
                if (!(i == 0 || i == index)) {
                    this.cells[i].style.display = "none";
                }
            }
        });
    }

})(rwd, rwd.ScreenSize, rwd.UIComponent);


; (function (Accordion, ScrollableTable, MultipleTable) {
    //INGRWD-175
    $("#outlet-content").onPage('content-replace', function() {
        // Rozmnażanie tabel na potrzeby xsmall
        $(".table_type2").each(function () {
            new ScrollableTable(this);
        });
    }); // ready
})(rwd.Accordion, rwd.ScrollableTable, rwd.MultipleTable);
